<template>
  <div class="p-10">
      <div class="my-4">
          <h2 class="text-4xl text-gray-600 font-bold">French</h2>                
            <div class="rounded-lg overflow-hidden shadow">
                                    <div style="padding: 56.25% 0 0 0; position: relative;">

      <iframe
        src="https://www.youtube.com/embed/Jr6ihuRMsqc"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        frameborder="0"
        allow=" fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
                  </div>

      </div>
      <div class="my-4">
          <h2 class="text-4xl text-gray-600 font-bold">Portugese</h2>                
            <div class="rounded-lg overflow-hidden shadow">
                                    <div style="padding: 56.25% 0 0 0; position: relative;">

      <iframe
        src="https://www.youtube.com/embed/pllnMBcOtBo"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        frameborder="0"
        allow=" fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
                  </div>

      </div>
            <div class="my-4">
          <h2 class="text-4xl text-gray-600 font-bold">English</h2>                
            <div class="rounded-lg overflow-hidden shadow">
                                    <div style="padding: 56.25% 0 0 0; position: relative;">

      <iframe
        src="https://www.youtube.com/embed/Sl31ho1-2Mw"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        frameborder="0"
        allow=" fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
                  </div>

      </div>
  </div>
</template>

<script>
export default {
  name: "youtube",
};
</script>

<style></style>
