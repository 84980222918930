<template>
  <section class="bg-white">
    <div
      class="
        max-w-screen-xl
        px-4
        py-12
        mx-auto
        space-y-8
        overflow-hidden
        sm:px-6
        lg:px-8
      "
    >
      <p class="mt-8 text-base leading-6 text-center text-gray-400">
        © 2021 Ezpos
      </p>
    </div>
  </section>
</template>

<script>
export default {
    name:'flooter'
};
</script>

<style>
</style>