<template>
  <div class="h-64 rounded-lg flex flex-col border-4 bg-blue-600">
  <div class="my-auto " v-html="$t('banner')">

  </div>
</div>
</template>

<script>
export default {
    name:"banner"
}
</script>

<style>

</style>